html, body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  width: 100%;
  display: flex;
  background: #f7faf7;
  color: #282c34;
}

body, button {
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  flex: 1;
  display: flex;
  width: 100vw;
}

p {
  line-height: 2em;
}

a {
  color: #269E19;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

a:visited {
  color: #1f8014;
}

a:hover, a.active {
  color: #196610;
}
